import { isAndroid, isIOS } from "react-device-detect";

export interface CopyOptionProps {
    id: string;
    title: string;
    icon: string;
    copy_message: string;
    show_option: boolean;
    copy_for_email: boolean;
    copy_for_embed?: boolean;
}
export const SHARE_OPTIONS: CopyOptionProps[] = [
    { id: "email", title: "Copy Link to Share via Email", icon: "/icons/email.svg", copy_message: "Copied Link for Email", show_option: !isAndroid, copy_for_email: true },
    { id: "link", title: "Copy link (works for most messaging apps)", icon: "/icons/link.svg", copy_message: "Copied Link", show_option: true, copy_for_email: false },
    {
        id: "embed",
        title: "Copy HTML to embed clickable video image",
        icon: "/icons/embed-code.svg",
        copy_message: "Copied HTML to embed clickable video image",
        show_option: true,
        copy_for_email: false,
        copy_for_embed: true
    },
    { id: "slack", title: "Copy Link to Share via Slack", icon: "/icons/slack.svg", copy_message: "Copied Link for Slack", show_option: true, copy_for_email: false },
    { id: "linkedin", title: "Copy Link to Share via LinkedIn", icon: "/icons/linkedin.svg", copy_message: "Copied Link for LinkedIn", show_option: true, copy_for_email: false },
    {
        id: "twitter",
        title: "Copy Link to Share via X (Twitter)",
        icon: "/icons/twitter.svg",
        copy_message: "Copied Link for X (Twitter)",
        show_option: true,
        copy_for_email: false
    },
    { id: "facebook", title: "Copy Link to Share via Facebook", icon: "/icons/facebook.svg", copy_message: "Copied Link for Facebook", show_option: true, copy_for_email: false }
];
