import { FC, useEffect, useState } from "react";
import { PostStatus } from "@emovid/payloads-library";
import copy from "copy-to-clipboard";
import { Skeleton } from "@mui/material";
import ToastService from "../../Common/ToastService";
import { Paths, replaceEventIdInPath } from "../../Common/AppRoutes";
import HttpService from "../../Services/HttpService";
import { getHTMLForSharing, postShared, pushTagEvent, updatePostById } from "../../Common/Helper";
import ShareIconsStrip from "./ShareIconsStrip";
import { CopyOptionProps, SHARE_OPTIONS } from "./ShareOptions";
import { Desktop, Mobile } from "../../Common/Responsive";
import EmbedOptionsDialog from "./EmbedOptionsDialog";

interface SharePluginProps {
    transcriptAvailable: boolean;
    maxWidth?: string;
    title?: string;
    emovidId?: string;
    postId?: string;
    userId?: string;
    creatorName?: string;
    emailThumbnailUrl?: string;
    gifUrl?: string;
    duration?: number;
    privateReplies: boolean;
    originalRecipientsCanView: boolean;
    signedInUsersCanView: boolean;
    onCopy?: () => void;
    updateStatusOnCopy?: boolean;
    aiSummary?: string;
    notes?: string;
}

export const emovidViewPath = (emovidId: string, postId: string, queryParams: any = {}): string => {
    if (postId) queryParams["show_post"] = postId;
    if (emovidId) return `${window.location.origin}${replaceEventIdInPath(Paths.viewEmovid, emovidId)}?${new URLSearchParams(queryParams).toString()}`;
    return "";
};

const SharePlugin: FC<SharePluginProps> = ({
    emovidId,
    transcriptAvailable,
    postId,
    userId,
    privateReplies,
    originalRecipientsCanView,
    signedInUsersCanView,
    creatorName,
    gifUrl,
    emailThumbnailUrl,
    duration,
    updateStatusOnCopy,
    onCopy,
    aiSummary,
    maxWidth,
    notes
}: SharePluginProps) => {
    const [copyInProgress, setCopyInProgress] = useState<boolean>(false);
    const [showEmbedDialog, setShowEmbedDialog] = useState<boolean>(false);
    const [shareLink, setShareLink] = useState<string>("");

    useEffect(() => {
        if (emovidId && postId) {
            const url = emovidViewPath(emovidId, postId);
            if (url) generateShortURL(url);
        }
    }, [emovidId, postId]);

    const generateShortURL = async (url: string) => {
        try {
            const shortUrlResponse = await HttpService.postFormData(`/v1/post/short/url`, { url }, false, true);
            setShareLink(shortUrlResponse.short_url);
        } catch (error) {
            console.error("Error generating short url: ", error);
            if (emovidId && postId) setShareLink(emovidViewPath(emovidId, postId));
        }
    };

    const copyEmbed = () => {
        setShowEmbedDialog(true);
    };

    const copyHTML = async () => {
        setCopyInProgress(true);
        if (emailThumbnailUrl) {
            try {
                window.ClipboardItem
                    ? await navigator.clipboard.write([new ClipboardItem({ "text/html": getHTML(gifUrl || emailThumbnailUrl) })])
                    : copy((await getHTML(gifUrl || emailThumbnailUrl)) as string, { format: "text/html" });
                if (postId && !!updateStatusOnCopy) await updatePostById(postId, { post_status: PostStatus.DRAFT_SHARABLE_LINK });
                onCopy?.();
                if (postId && userId) postShared(userId, postId, duration || 0, "email");
                ToastService.success("Copied for Email!");
            } catch (error) {
                console.log("Error: ", error);
            }
            shareLinkCopyEvent(true, SHARE_OPTIONS[0].id);
        } else copyText(SHARE_OPTIONS[1]);
        setCopyInProgress(false);
    };

    const copyText = async (option: CopyOptionProps) => {
        setCopyInProgress(true);
        try {
            window.ClipboardItem
                ? await navigator.clipboard.write([new ClipboardItem({ "text/plain": new Blob([`${shareLink}`], { type: "text/plain" }) })])
                : copy(shareLink, { format: "text/plain" });
            if (postId && !!updateStatusOnCopy) await updatePostById(postId, { post_status: PostStatus.DRAFT_SHARABLE_LINK });
            onCopy?.();
            if (postId && userId) postShared(userId, postId, duration || 0, option.id);
            ToastService.success(option.copy_message);
        } catch (error) {
            console.log("Error: ", error);
        }
        setCopyInProgress(false);
        try {
            shareLinkCopyEvent(false, option.id);
        } catch (error) {
            console.error("Failed to track share link copy event:", error);
        }
    };

    const shareLinkCopyEvent = (asHtml: boolean, linkType: string) => {
        pushTagEvent("e_share_link_copied", {
            event_id: emovidId,
            post_id: postId,
            sec_private_replies: privateReplies ? "on" : "off",
            sec_only_email: originalRecipientsCanView ? "on" : "off",
            sec_only_signed_in: signedInUsersCanView ? "on" : "off",
            link_type: linkType,
            as_html: asHtml
        });
    };

    const getHTML = async (imageUrl: any) => {
        const html = await getHTMLForSharing(
            imageUrl,
            originalRecipientsCanView || signedInUsersCanView || false,
            shareLink,
            emovidViewPath(emovidId || "", postId || "", { scroll_to: "transcript" }),
            `${duration || 0}`,
            aiSummary || "",
            creatorName || "",
            notes ? emovidViewPath(emovidId || "", postId || "", { scroll_to: "notes" }) : ""
        );
        return window.ClipboardItem ? new Blob([html], { type: "text/html" }) : html;
    };

    return (
        <>
            {!shareLink && (
                <div>
                    <Desktop>
                        <div className="mt-3">
                            <div className="d-flex w-100 justify-content-evenly">
                                {[...Array(7)].map((_, index) => {
                                    return <Skeleton key={index} className="m-1 ms-2 me-2" variant="circular" height={50} width={50} animation="wave" />;
                                })}
                            </div>
                            <div className="d-flex w-100 justify-content-evenly mt-3">
                                <Skeleton className="m-2" variant="rounded" width={250} animation="wave" />
                            </div>
                        </div>
                    </Desktop>
                    <Mobile>
                        <div className="mt-3">
                            <div className="d-flex w-100 justify-content-evenly">
                                {[...Array(4)].map((_, index) => {
                                    return <Skeleton key={index} className="m-1 ms-2 me-2" variant="circular" height={50} width={50} animation="wave" />;
                                })}
                            </div>
                            <div className="d-flex w-100 justify-content-evenly mt-3">
                                <Skeleton className="m-2" variant="rounded" width={250} animation="wave" />
                            </div>
                        </div>
                    </Mobile>
                </div>
            )}
            {shareLink && (
                <ShareIconsStrip
                    transcriptAvailable={transcriptAvailable}
                    shareLink={shareLink}
                    copyInProgress={copyInProgress}
                    onCopyHTML={copyHTML}
                    onCopyText={copyText}
                    onCopyEmbed={copyEmbed}
                    maxWidth={maxWidth || "100%"}
                />
            )}
            <EmbedOptionsDialog
                show={showEmbedDialog}
                shareLink={shareLink}
                onCancelClick={() => setShowEmbedDialog(false)}
                emovidId={emovidId}
                postId={postId}
                emailThumbnailUrl={emailThumbnailUrl}
                updateStatusOnCopy={updateStatusOnCopy}
                originalRecipientsCanView={originalRecipientsCanView}
                signedInUsersCanView={signedInUsersCanView}
                privateReplies={privateReplies}
            />
        </>
    );
};

export default SharePlugin;
