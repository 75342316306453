import { IPostAttributes } from "@emovid/payloads-library";

export enum AuthenticityType {
    AuthenticityTypeNone = 0,
    AuthenticityTypeUnmodified = 1,
    AuthenticityTypeBody = 2,
    AuthenticityTypeFace = 3,
    AuthenticityTypeLoggedOutCreator = 4
}

export interface IPostResponseAttributes extends IPostAttributes {
    video_url?: string;
    thumbnail_url?: string;
    email_thumbnail_url?: string;
    gif_url?: string;
    created_at: string;
    is_creator: boolean;
    viewed_percent?: number;
    views_loaded?: boolean;
    hls_metadata?: any;
    authenticity_type?: AuthenticityType;
    logged_out_creator_email?: string;
}
