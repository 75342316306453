import React, { FC, useEffect, useState } from "react";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { isDesktop, isIOS, isTablet } from "react-device-detect";
import { CopyOptionProps, SHARE_OPTIONS } from "./ShareOptions";
import ToastService from "../../Common/ToastService";

interface ShareIconsStripProps {
    copyInProgress: boolean;
    transcriptAvailable: boolean;
    shareLink: string;
    maxWidth: string;
    onCopyHTML: () => void;
    onCopyText: (option: CopyOptionProps) => void;
    onCopyEmbed: () => void;
}

const ShareIconsStrip: FC<ShareIconsStripProps> = (props: ShareIconsStripProps) => {
    const shareIconsContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
    const [showScrollLeft, setShowScrollLeft] = useState<boolean>(false);
    const [showScrollRight, setShowScrollRight] = useState<boolean>(false);

    useEffect(() => {
        checkcontainerElementScroll();
    }, []);

    const checkcontainerElementScroll = () => {
        const containerElement = shareIconsContainerRef.current;
        if (containerElement && containerElement?.scrollWidth > containerElement?.offsetWidth) {
            if (containerElement.scrollLeft <= 0) {
                setShowScrollLeft(false);
                setShowScrollRight(true);
            } else if (containerElement.scrollLeft >= containerElement.scrollWidth - containerElement.offsetWidth - 20) {
                setShowScrollLeft(true);
                setShowScrollRight(false);
            } else {
                setShowScrollLeft(true);
                setShowScrollRight(true);
            }
        } else {
            setShowScrollLeft(false);
            setShowScrollRight(false);
        }
    };

    const leftArrowClicked = () => {
        const containerElement = shareIconsContainerRef.current;
        if (containerElement) containerElement.scrollLeft = 0;
    };

    const rightArrowClicked = () => {
        const containerElement = shareIconsContainerRef.current;
        if (containerElement) containerElement.scrollLeft = containerElement.scrollWidth;
    };

    const handleCopyEmbedClick = () => {
        if (isDesktop) props.onCopyEmbed?.();
        else ToastService.info("Embed video image option is available on Desktop devices only");
    };

    return (
        <div>
            <div className={"w-100 " + (props.copyInProgress ? "" : "opacity-0")}>
                <LinearProgress />
            </div>
            <div className="position-relative">
                <div
                    style={{ maxWidth: props.maxWidth, overflowX: "auto", overflowY: "hidden", margin: "auto" }}
                    ref={shareIconsContainerRef}
                    onScroll={checkcontainerElementScroll}
                >
                    <div className={"d-flex " + (isDesktop || isTablet ? "justify-content-center" : "")}>
                        {SHARE_OPTIONS.map((option: CopyOptionProps, i: number) => (
                            <Tooltip key={i} title={option.copy_for_email && !props.transcriptAvailable ? "Generating AI summary ..." : option.title}>
                                <IconButton
                                    disabled={props.copyInProgress}
                                    className={"m-1 ms-2 me-2 share-button text-white " + (option.show_option ? "" : "d-none")}
                                    onClick={() => (option.copy_for_email ? props.onCopyHTML?.() : option.copy_for_embed ? handleCopyEmbedClick() : props.onCopyText?.(option))}
                                >
                                    <img alt={option.title} src={option.icon} width={45} />
                                </IconButton>
                            </Tooltip>
                        ))}
                    </div>
                </div>
                {showScrollLeft && (
                    <div className="share-icons-nav arrow-left" onClick={leftArrowClicked}>
                        <div className="icon">
                            <KeyboardArrowLeftRoundedIcon />
                        </div>
                    </div>
                )}
                {showScrollRight && (
                    <div className="share-icons-nav arrow-right" onClick={rightArrowClicked}>
                        <div className="icon">
                            <KeyboardArrowRightRoundedIcon />
                        </div>
                    </div>
                )}
            </div>
            <div className="text-truncate text-center p-2 m-auto label" style={{ maxWidth: "300px" }}>
                {props.shareLink}
            </div>
        </div>
    );
};

export default ShareIconsStrip;
