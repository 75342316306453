import { FC, useEffect, useState } from "react";
import { Dialog, IconButton, Tooltip } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AspectRatioRoundedIcon from "@mui/icons-material/AspectRatioRounded";
import VideoPlayer from "./VideoPlayer";
import { AuthenticityType } from "../../Interfaces/IPostResponseAttributes";
import { DurationUpdateEvent } from "../../Interfaces/DurationUpdateEvent";

interface ResizableDialogVideoPlayerProps {
    open: boolean;
    videoUrl: string;
    hlsUrl?: string;
    thumbnail: string;
    playbackStartTime: number;
    authenticityType: AuthenticityType | null | undefined;
    loggedOutCreatorEmail: string;
    initialWidth: number;
    uuid?: string;
    reloadThumbnail?: boolean;
    onPreviousVideo?: () => void;
    onNextVideo?: () => void;
    onPlaybackStarted?: () => void;
    onTimeUpdate?: (event: DurationUpdateEvent) => void;
    onCustomFullscreenToggle?: (currPlayerTime: number) => void;
    showTryNow?: () => void;
    onClose?: () => void;
}

const ResizableDialogVideoPlayer: FC<ResizableDialogVideoPlayerProps> = ({
    open,
    videoUrl,
    hlsUrl,
    thumbnail,
    loggedOutCreatorEmail,
    playbackStartTime,
    authenticityType,
    initialWidth,
    uuid,
    reloadThumbnail,
    onPreviousVideo,
    onNextVideo,
    onPlaybackStarted,
    onTimeUpdate,
    onCustomFullscreenToggle,
    showTryNow,
    onClose
}: ResizableDialogVideoPlayerProps) => {
    const [playerDialogWidth, setPlayerDialogWidth] = useState(initialWidth || 600);

    useEffect(() => {
        setPlayerDialogWidth(initialWidth);
    }, [initialWidth]);

    const handler = (mouseDownEvent: any) => {
        const onMouseMove = (mouseMoveEvent: any) => {
            setPlayerDialogWidth(Math.max(playerDialogWidth + 2 * (mouseMoveEvent.pageX - mouseDownEvent.pageX), 400));
        };
        const onMouseUp = () => {
            document.body.removeEventListener("mousemove", onMouseMove);
        };
        const onMouseOut = (e: any) => {
            e = e ? e : window.event;
            var from = e.relatedTarget || e.toElement;
            if (!from || from.nodeName === "HTML") document.body.removeEventListener("mousemove", onMouseMove);
        };
        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseout", onMouseOut);
        document.body.addEventListener("mouseup", onMouseUp, { once: true });
    };

    return (
        <Dialog open={open} maxWidth={false} className="fullscreen-video-popup" onClose={onClose}>
            <div className="position-relative" style={{ width: playerDialogWidth, height: "100%" }}>
                <VideoPlayer
                    autoplay={true}
                    fluid={true}
                    hideFullscreenOption={true}
                    url={videoUrl}
                    hlsUrl={hlsUrl}
                    thumbnail={thumbnail}
                    showCustomControls={true}
                    reloadThumbnail={reloadThumbnail}
                    playbackStartTime={playbackStartTime}
                    authenticityType={authenticityType}
                    loggedOutCreatorEmail={loggedOutCreatorEmail}
                    onPreviousVideo={onPreviousVideo}
                    onNextVideo={onNextVideo}
                    onPlaybackStarted={onPlaybackStarted}
                    onTimeUpdate={onTimeUpdate}
                    onCustomFullscreenToggle={onCustomFullscreenToggle}
                    showTryNow={showTryNow}
                    uuid={uuid}
                />
                <Tooltip title="Close dialog">
                    <IconButton className="position-absolute text-white" aria-label="Close" onClick={onClose} style={{ top: "5px", right: "0" }}>
                        <CloseRoundedIcon className="svg-shadow" sx={{ fontSize: 30 }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Resize video by pressing down and dragging">
                    <IconButton className="drag-handle svg-shadow" type="button" onMouseDown={handler}>
                        <AspectRatioRoundedIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </Dialog>
    );
};
export default ResizableDialogVideoPlayer;
