import { FC, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Switch } from "@mui/material";
import Popup from "../../Common/Popup";
import { PrivacySetting } from "../CreateEmovid/CreateEmovid";
import { pushTagEvent } from "../../Common/Helper";
import { ICreatedEvents } from "../../Interfaces/ICreatedEvents";
import HttpService from "../../Services/HttpService";
import ToastService from "../../Common/ToastService";

interface PrivacySettingsDialogProps {
    post: ICreatedEvents;
    open: boolean;
    disabled?: boolean;
    onClose: Function;
    onSuccess: Function;
}

const PrivacySettingsDialog: FC<PrivacySettingsDialogProps> = ({ post, open, disabled, onClose, onSuccess }: PrivacySettingsDialogProps) => {
    const [originalRecipientsCanView, setOriginalRecipientsCanView] = useState<boolean>(post.privacy_settings?.recipients_can_view_post || false);
    const [signedInUsersCanView, setSignedInUsersCanView] = useState<boolean>(post.privacy_settings?.signed_in_users_can_view_post || false);
    const [privateReplies, setPrivateReplies] = useState<boolean>(post.privacy_settings?.keep_replies_private || false);

    useEffect(() => {
        setOriginalRecipientsCanView(post.privacy_settings?.recipients_can_view_post || false);
        setSignedInUsersCanView(post.privacy_settings?.signed_in_users_can_view_post || false);
        setPrivateReplies(post.privacy_settings?.keep_replies_private || false);
    }, [post]);

    const updatePrivacySettingsInUI = (privacySetting: PrivacySetting, value: boolean) => {
        if (privacySetting === PrivacySetting.ORIGINAL_RECIPIENTS_CAN_VIEW) {
            setSignedInUsersCanView(value);
            setOriginalRecipientsCanView(value);
        } else if (privacySetting === PrivacySetting.SIGNED_IN_USERS_CAN_VIEW) {
            setSignedInUsersCanView(value);
        } else if (privacySetting === PrivacySetting.PRIVATE_REPLIES) {
            setPrivateReplies(value);
        }
    };

    const savePrivacySettings = () => {
        pushTagEvent("e_created_vids_security", { post_id: post?.post_id, event_id: post?.emovid_id });
        HttpService.putFormData(`/v1/event/${post?.emovid_id}/privacy/settings`, {
            recipients_can_view_post: originalRecipientsCanView,
            signed_in_users_can_view_post: signedInUsersCanView,
            keep_replies_private: privateReplies
        })
            .then(() => {
                onClose(false);
                onSuccess();
                pushTagEvent("e_inbox_save_sec_settings", {
                    post_id: post?.post_id,
                    event_id: post?.emovid_id,
                    sec_only_email: originalRecipientsCanView ? "on" : "off",
                    sec_only_signed_in: signedInUsersCanView ? "on" : "off",
                    sec_private_replies: privateReplies ? "on" : "off"
                });
            })
            .catch(error => {
                ToastService.alertableError(error.message || "Unable to save privacy settings", error);
            });
    };

    return (
        <Popup isOpen={open} onClose={() => onClose()} className="security-settings-popup">
            <div className="container p-3">
                <h3 className="text-dark m-auto pb-3 text-white text-center pe-3">Privacy & Security Settings</h3>
                {post?.subject && <h3 className="text-dark m-auto pt-2 pb-3 text-white fs-5">{post?.subject}</h3>}
                <IconButton className="close-icon text-white mt-3" onClick={() => onClose()}>
                    <CloseIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <div className="d-flex flex-wrap flex-column justify-content-center controls">
                    <div className="d-flex justify-content-between p-1">
                        <span className="mt-2">Only email recipients can view</span>
                        <Switch
                            className="ms-4"
                            checked={originalRecipientsCanView}
                            disabled={disabled}
                            onChange={() => updatePrivacySettingsInUI(PrivacySetting.ORIGINAL_RECIPIENTS_CAN_VIEW, !originalRecipientsCanView)}
                            inputProps={{ "aria-label": "only email recipients can view" }}
                        />
                    </div>
                    <div className="d-flex justify-content-between p-1">
                        <span className="mt-2">Only signed-in users can view</span>
                        <Switch
                            className="ms-4"
                            checked={signedInUsersCanView}
                            onChange={() => updatePrivacySettingsInUI(PrivacySetting.SIGNED_IN_USERS_CAN_VIEW, !signedInUsersCanView)}
                            disabled={originalRecipientsCanView === true || disabled}
                            inputProps={{ "aria-label": "only signed-in users can view" }}
                        />
                    </div>
                    <div className="d-flex justify-content-between p-1">
                        <span className="mt-2">Bcc mode (Private)</span>
                        <Switch
                            className="ms-4"
                            disabled={disabled}
                            checked={privateReplies}
                            onChange={() => updatePrivacySettingsInUI(PrivacySetting.PRIVATE_REPLIES, !privateReplies)}
                            inputProps={{ "aria-label": "Bcc mode (Private)" }}
                        />
                    </div>
                </div>
                <div className="mt-4 d-flex justify-content-center">
                    <button className="btn btn-outline-primary text-white mr-3" disabled={disabled} data-tip="Cancel" onClick={() => onClose(false)}>
                        Cancel
                    </button>
                    <button className="btn btn-primary text-white ml-3" disabled={disabled} data-tip="Save" onClick={savePrivacySettings}>
                        Save
                    </button>
                </div>
            </div>
        </Popup>
    );
};

export default PrivacySettingsDialog;
