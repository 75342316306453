import React, { useState } from "react";
import videojs from "video.js";
import "videojs-seek-buttons";
import Fade from "@mui/material/Fade";
import { IconButton, CircularProgress } from "@mui/material";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
import SkipPreviousRoundedIcon from "@mui/icons-material/SkipPreviousRounded";
import PauseCircleFilledRoundedIcon from "@mui/icons-material/PauseCircleFilledRounded";
import Tooltip from "@mui/material/Tooltip";
import { useSwipeable } from "react-swipeable";
import { isMobile } from "react-device-detect";
import { debounce } from "lodash";
import { exitPlayerFullScreen } from "../Common/Helper";
import { pushTagEvent } from "../../Common/Helper";
import { AuthenticityType } from "../../Interfaces/IPostResponseAttributes";
import TryNowButton from "../../Common/TryNowButton";

let videoPlaybackRetryCount = 0;
let lastTagEventTime = 0;
const Player = props => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const {
        options,
        onReady,
        onLoadedData,
        onPlaybackStarted,
        fullscreenOnStart,
        showCustomControls,
        onPreviousVideo,
        onNextVideo,
        onTimeUpdate,
        hideMarketingMessage,
        authenticityType,
        showTryNow,
        showTransparentPlayPause,
        disableSkipBtns,
        loggedOutCreatorEmail,
        uuid
    } = props;
    const [error, setError] = useState(null);
    const [showPlayButton, setShowPlayButton] = useState(!options.autoplay);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [showCustomOverlay, setShowCustomOverlay] = useState(true);
    const [playbackComplete, setPlaybackComplete] = useState(false);
    const [authenticitySealTitle, setAuthenticitySealTitle] = useState("");
    const [codecError, setCodecError] = useState(null);

    const handlers = useSwipeable({
        onSwiped: eventData => handleSwipe(eventData),
        preventScrollOnSwipe: false
    });

    const overlayDismissDebounce = debounce(() => {
        try {
            if (playerRef.current?.paused()) return;
            setShowCustomOverlay(false);
        } catch (error) {
            // do nothing
        }
    }, 2000);

    React.useEffect(() => {
        let title = null;
        // if (authenticityType === AuthenticityType.AuthenticityTypeUnmodified) title = "No filters/smoothing applied";
        // else if (authenticityType === AuthenticityType.AuthenticityTypeBody) title = "Background or face smoothing applied";
        setAuthenticitySealTitle(title);
    }, [authenticityType]);

    React.useEffect(() => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;
            const player = (playerRef.current = videojs(videoElement, options, () => {
                videoPlaybackRetryCount = 0;
                lastTagEventTime = 0;
                onReady && onReady(player);
                if (!disableSkipBtns && !isMobile) {
                    let prevBtn = player.controlBar.addChild("button", {}, 0);
                    let prevBtnDom = prevBtn.el();
                    prevBtnDom.innerHTML = '<span class="vjs-icon-previous-item"></span>';
                    prevBtnDom.onclick = previousBtnClicked;
                    let nextBtn = player.controlBar.addChild("button", {}, 2);
                    let nextBtnDom = nextBtn.el();
                    nextBtnDom.innerHTML = '<span class="vjs-icon-next-item"></span>';
                    nextBtnDom.onclick = nextBtnClicked;
                }
                player.on("play", () => {
                    console.log("player play");
                    setShowPlayButton(false);
                    setShowCustomOverlay(false);
                    player.currentTime() < 0.1 && onPlaybackStarted && onPlaybackStarted();
                    setPlaybackComplete(false);
                    if (fullscreenOnStart) setTimeout(() => player.requestFullscreen(), 500);
                    if (props.draftTrackingData)
                        pushTagEvent("e_video_draft_play_started", {
                            ...props.draftTrackingData,
                            video_length: player.duration()
                        });
                });
                player.on("fullscreenToggle", () => {
                    console.log("custom fullscreenToggle");
                    player.pause();
                    props.onCustomFullscreenToggle && props.onCustomFullscreenToggle(player.currentTime());
                });
                player.on("pause", () => {
                    console.log("player pause");
                    if (!player.seeking()) {
                        setShowCustomOverlay(player.paused());
                        setShowPlayButton(player.paused());
                    }
                });
                player.on("error", error => {
                    console.log("player error");
                    setVideoLoaded(false);
                    videoPlaybackRetryCount = videoPlaybackRetryCount + 1;
                    if (props.hlsUrl) {
                        console.log("falling back to normal video HLS URL failed: ", props.hlsUrl);
                        props.fallbackToNormalVideo(player);
                        setTimeout(() => player && player.load(), 2000);
                    } else if (props.isHEVCCodec) {
                        console.log("retrying for hevc codec");
                        if (videoPlaybackRetryCount < 10) setTimeout(() => player && player.load(), 2000);
                        else setCodecError(true);
                    } else {
                        console.log("retrying for normal video");
                        if (videoPlaybackRetryCount < 10) setTimeout(() => player && player.load(), 2000);
                        else setError(error);
                    }
                });
                player.on("touchstart", e => {
                    if (e.target.nodeName === "VIDEO") togglePlayBtnClicked();
                });
                player.on("ended", () => {
                    console.log("player ended");
                    setShowCustomOverlay(true);
                    setPlaybackComplete(true);
                    setShowPlayButton(true);
                    if (props.draftTrackingData) {
                        pushTagEvent("e_video_draft_play_ended", {
                            ...props.draftTrackingData,
                            video_length: player.duration()
                        });
                    }
                    lastTagEventTime = 0;
                    if (props.playMultiple) {
                        nextBtnClicked();
                    } else exitPlayerFullScreen(player);
                    props.onPlaybackEnded && props.onPlaybackEnded();
                });
                player.on("timeupdate", () => {
                    onTimeUpdate && onTimeUpdate({ currentTime: player.currentTime(), duration: player.duration() });
                    if (props.draftTrackingData) {
                        let currTime = parseInt(`${player.currentTime() / 10.0}`);
                        if (currTime > 0 && currTime !== lastTagEventTime) {
                            pushTagEvent("e_video_draft_progress", {
                                ...props.draftTrackingData,
                                video_length: player.duration(),
                                video_progress: currTime * 10
                            });
                            lastTagEventTime = currTime;
                        }
                    }
                });
                player.on("loadeddata", () => {
                    console.log("player loadeddata");
                    setVideoLoaded(true);
                    if (props.playbackStartTime > 0) player.currentTime(props.playbackStartTime);
                    else if (props.playbackProgressPercent > 0 && props.playbackProgressPercent < 100) {
                        player.currentTime(Math.max((player.duration() * props.playbackProgressPercent) / 100.0, 0));
                    }
                    setError(null);
                    setCodecError(null);
                    videoPlaybackRetryCount = 0;
                });
                player.on("loadedmetadata", () => {
                    if (player.controlBar.PlaybackRateMenuButtonDisableTap) player.controlBar.PlaybackRateMenuButtonDisableTap.show();
                    onLoadedData && onLoadedData();
                    console.log("player loadedmetadata");
                    setVideoLoaded(true);
                    if (props.onSetVideoDimensions && videoRef && videoRef.current && videoRef.current.videoWidth && videoRef.current.videoHeight) {
                        props.onSetVideoDimensions({
                            width: videoRef.current.videoWidth,
                            height: videoRef.current.videoHeight
                        });
                    }
                    if (videoRef && videoRef.current && videoRef.current.duration === Infinity) {
                        videoRef.current.currentTime = 1e101;
                        videoRef.current.ontimeupdate = function () {
                            this.ontimeupdate = () => {
                                return;
                            };
                            this.currentTime = 0.1;
                            return;
                        };
                    }
                    if (props.setVideoData)
                        props.setVideoData({ duration: Math.round(player.duration()), dimensions: `${videoRef.current.videoWidth}x${videoRef.current.videoHeight}` });
                });
                player.on("ratechange", () => {
                    if (player.controlBar.PlaybackRateMenuButtonDisableTap) player.controlBar.PlaybackRateMenuButtonDisableTap.labelEl_.textContent = player.playbackRate() + "x";
                });
            }));
            if (!options.disableJumpBtns) {
                player.seekButtons({
                    forward: 15,
                    back: 15,
                    forwardIndex: 5,
                    backIndex: 4
                });
            }
        }
        if (props.reloadThumbnail && playerRef.current.paused()) {
            const interval = setInterval(() => {
                // try/catch because there's a race condition here as the player gets destructed if the user moves too fast (ie, automation tests)
                try {
                    playerRef.current.poster(options.poster);
                } catch (e) {
                    console.error(e);
                }
                clearInterval(interval);
            }, 50);
            playerRef.current.pause();
            playerRef.current.src(playerRef.current.src());
        }
        if (props.reloadVideo) {
            lastTagEventTime = 0;
            videoPlaybackRetryCount = 0;
            setVideoLoaded(false);
            setPlaybackComplete(false);
            if (props.playbackProgressPercent > 0 && props.playbackProgressPercent < 100) {
                playerRef.current.currentTime(Math.max((playerRef.current.duration() * props.playbackProgressPercent) / 100.0, 0)); // start from a second ago to give context
            }
            playerRef.current.loadMedia({ src: { src: options.src }, poster: options.poster }, () => {
                setVideoLoaded(true);
                if ((showCustomControls && !playerRef.current.paused()) || options.autoplay) {
                    if (fullscreenOnStart && !playerRef.current.isFullscreen()) setTimeout(() => playerRef.current.requestFullscreen(), 500);
                }
                if (showCustomControls) {
                    setShowPlayButton(true);
                    setShowCustomOverlay(true);
                }
                if ((showCustomControls && !playerRef.current.paused()) || options.autoplay) {
                    playerRef.current.play();
                    setShowPlayButton(false);
                    setShowCustomOverlay(false);
                }
            });
        }
        if (props.reloadThumbnail || props.reloadVideo) props.resetReload && props.resetReload();
    });

    const handleTogglePlayClick = e => {
        e.stopPropagation();
        togglePlayBtnClicked();
    };

    const showOverlayForDurationAndHide = () => {
        setShowCustomOverlay(true);
        if (playerRef.current.paused()) return;
        overlayDismissDebounce.cancel();
        overlayDismissDebounce();
    };

    const handleSwipe = event => {
        if (["Left"].includes(event.dir)) nextBtnClicked();
        if (["Right"].includes(event.dir)) previousBtnClicked();
    };

    const togglePlayBtnClicked = () => {
        if (playerRef.current) {
            if (playerRef.current.paused()) {
                playerRef.current.play();
                setShowPlayButton(false);
            } else {
                playerRef.current.pause();
                setShowPlayButton(true);
            }
        }
    };

    const previousBtnClicked = () => {
        onPreviousVideo && onPreviousVideo();
        if (!options.autoplay) setTimeout(() => togglePlayBtnClicked(), 200);
    };

    const nextBtnClicked = () => {
        onNextVideo && onNextVideo();
        if (!options.autoplay) setTimeout(() => togglePlayBtnClicked(), 200);
    };

    const showCustomPlayBtns = () => {
        if (playerRef.current) if (playerRef.current.paused()) return true;
        return false;
    };

    const onRetry = () => {
        window.location.reload();
    };

    const onCodecRetry = () => {
        if (playerRef.current) onReady && onReady(playerRef.current);
        videoRef.current && videoRef.current.load();
    };

    return (
        <div className="position-relative" {...handlers} onMouseMove={showOverlayForDurationAndHide}>
            <div
                data-vjs-player
                style={{
                    height: props.height ? props.height : "",
                    maxHeight: props.maxHeight ? props.maxHeight : props.height ? props.height : ""
                }}
            >
                <video
                    ref={videoRef}
                    className={
                        "video-js vjs-big-play-centered vjs-gratiphied-theme player-height" +
                        (props.showCustomControls ? " custom-controls " : "") +
                        (props.controlBarBottomMargin ? ` controls-${props.controlBarBottomMargin} ` : "")
                    }
                    playsInline
                />
                {error && (
                    <div className="error-info-container">
                        <div className="error-message">This video is being processed...</div>
                        <div className="error-message">Please try again</div>
                    </div>
                )}
                {codecError && props.mode !== "upload" && (
                    <div className="error-info-container">
                        <div className="error-message">This video is being processed...</div>
                        <div className="error-message">Please try again</div>
                    </div>
                )}
                {codecError && props.mode === "upload" && (
                    <div className="error-info-container">
                        <div className="error-message">This video format is not supported in your browser</div>
                        <div className="error-message">Preview available only after processing</div>
                    </div>
                )}
            </div>
            {error && (
                <div className="retry-button-container pb-3">
                    <div className="primary-button" onClick={onRetry}>
                        <div className="retry-button">
                            <i className="fas fa-redo"></i>
                            <span>Retry</span>
                        </div>
                    </div>
                </div>
            )}
            {codecError && (
                <div className="retry-button-container pb-3">
                    {props.mode !== "upload" && (
                        <div className="primary-button" onClick={onCodecRetry}>
                            <div className="retry-button">
                                <i className="fas fa-redo"></i>
                                <span>Retry</span>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {showCustomControls && !error && !codecError && (
                <Fade in={showCustomOverlay}>
                    <div className={"video-end-message" + (playbackComplete && !hideMarketingMessage ? " dark-bg " : "")}>
                        <div className="position-relative d-table h-100 w-100">
                            <div className="video-end-message-container text-center w-100" onClick={togglePlayBtnClicked}>
                                {playbackComplete && !hideMarketingMessage && <div className="p-3">A short video message from you can change a person's day</div>}
                                <div className="d-flex justify-content-center" onClick={e => e.stopPropagation()}>
                                    {!disableSkipBtns && showCustomPlayBtns() && (
                                        <IconButton className="previous-btn" title="Previous video" onClick={previousBtnClicked}>
                                            <SkipPreviousRoundedIcon className="font-large svg-shadow" fontSize="large" />
                                        </IconButton>
                                    )}
                                    &nbsp;
                                    {!showTransparentPlayPause && showCustomPlayBtns() && (
                                        <IconButton title={showPlayButton ? "Play" : "Pause"} onClick={e => handleTogglePlayClick(e)} disabled={!videoLoaded}>
                                            {!showPlayButton && <PauseCircleFilledRoundedIcon className="font-large svg-shadow" fontSize="large" />}
                                            {showPlayButton && <PlayArrowRoundedIcon className="font-large svg-shadow" fontSize="large" />}
                                        </IconButton>
                                    )}
                                    {showTransparentPlayPause && showCustomPlayBtns() && (
                                        <div
                                            className="btn btn-light play-button-overlay-container text-white"
                                            title={showPlayButton ? "Play" : "Pause"}
                                            onClick={e => handleTogglePlayClick(e)}
                                            disabled={!videoLoaded}
                                        >
                                            {!showPlayButton && <PauseRoundedIcon className="font-large svg-shadow" fontSize="large" />}
                                            {showPlayButton && <PlayArrowRoundedIcon className="font-large svg-shadow" fontSize="large" />}
                                        </div>
                                    )}
                                    &nbsp;
                                    {!disableSkipBtns && showCustomPlayBtns() && (
                                        <IconButton className="next-btn" title="Next video" onClick={nextBtnClicked}>
                                            <SkipNextRoundedIcon className="font-large svg-shadow" fontSize="large" />
                                        </IconButton>
                                    )}
                                    {!videoLoaded && (
                                        <CircularProgress color="inherit" size={40} sx={{ position: "absolute", top: "calc(50% - 20px)", left: "calc(50% - 20px)" }} />
                                    )}
                                </div>
                                <Tooltip title={authenticitySealTitle}>
                                    <div
                                        className={"authenticity-seal " + (authenticityType === AuthenticityType.AuthenticityTypeLoggedOutCreator ? "full-width" : "")}
                                        style={{
                                            opacity: showPlayButton && videoLoaded && authenticityType && !playbackComplete ? 1 : 0
                                        }}
                                    >
                                        {authenticityType === AuthenticityType.AuthenticityTypeLoggedOutCreator && (
                                            <div>This message originated from an e-mail sent to: {loggedOutCreatorEmail}</div>
                                        )}
                                        {authenticityType === AuthenticityType.AuthenticityTypeBody && (
                                            <img src={`/images/authenticity/authentic_body.gif?rnd=${uuid || ""}`} alt="Authentic Body" />
                                        )}
                                        {authenticityType === AuthenticityType.AuthenticityTypeFace && (
                                            <img src={`/images/authenticity/authentic_face.gif?rnd=${uuid || ""}`} alt="Authentic Face" />
                                        )}
                                        {authenticityType === AuthenticityType.AuthenticityTypeUnmodified && (
                                            <img src={`/images/authenticity/authentic_all.gif?rnd=${uuid || ""}`} alt="Authentic Person" />
                                        )}
                                        {authenticityType !== AuthenticityType.AuthenticityTypeLoggedOutCreator && (
                                            <div className="position-absolute ring">
                                                <img src={`/images/authenticity/authenticity_ring.gif?rnd=${uuid || ""}`} alt="Authenticity Ring" />
                                            </div>
                                        )}
                                    </div>
                                </Tooltip>
                                {playbackComplete && !hideMarketingMessage && showTryNow && (
                                    <div className="position-relative" onClick={e => e.stopPropagation()}>
                                        <div>Just takes seconds</div>
                                        <TryNowButton className="btn btn-primary mt-3"></TryNowButton>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Fade>
            )}
        </div>
    );
};

export default Player;
