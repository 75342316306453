import { FC, useRef, useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ClickAwayListener, List, ListItem, ListItemButton, Paper, Popper, Tooltip } from "@mui/material";
import { isEmail } from "./Helper";

const theme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#dd4f5d",
                    color: "white",
                    border: "1px solid #dd4f5d"
                },
                arrow: {
                    "&:before": {
                        border: "1px solid #dd4f5d"
                    },
                    color: "#dd4f5d"
                }
            }
        }
    }
});

interface EmailSuggestion {
    email: string;
    name: string;
}

interface AddEmailControlProps {
    id?: string;
    suggestions?: EmailSuggestion[];
    addingEmail?: boolean;
    onBlur?: Function;
    onEmailsAdd?: Function;
    onBackKeyPressed?: Function;
    onTextEntered?: Function;
}

const AddEmailControl: FC<AddEmailControlProps> = (props: AddEmailControlProps) => {
    const errorMessage = "Please enter valid email addresses.";
    const inputRef = useRef(null);
    const [textEntered, setTextEntered] = useState<string>("");
    const [filteredSuggestions, setFilteredSuggestions] = useState<EmailSuggestion[]>(props.suggestions || []);
    const [suggestionsOpen, setSuggestionsOpen] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const compareText = (textA: string, textB: string, textToCompare: string) => {
        if (textA.startsWith(textToCompare) && textB.startsWith(textToCompare)) return -1;
        if (textA.startsWith(textToCompare) && !textB.startsWith(textToCompare)) return -1;
        if (!textA.startsWith(textToCompare) && textB.startsWith(textToCompare)) return 1;
        return undefined;
    };

    useEffect(() => {
        let textToCompare = textEntered.toLowerCase();
        let filteredResult =
            props.suggestions?.filter(
                (option: EmailSuggestion) => (option.name || "").toLowerCase().indexOf(textToCompare) >= 0 || (option.email || "").toLowerCase().indexOf(textToCompare) >= 0
            ) || [];
        let sortedResults = filteredResult.sort((a: EmailSuggestion, b: EmailSuggestion) => {
            let firstNameA = ((a.name || "").split(" ")[0] || "").toLowerCase().trim();
            let firstNameB = ((b.name || "").split(" ")[0] || "").toLowerCase().trim();
            let lastNameA = ((a.name || "").split(" ")[1] || "").toLowerCase().trim();
            let lastNameB = ((b.name || "").split(" ")[1] || "").toLowerCase().trim();
            let firstNameComparison = compareText(firstNameA, firstNameB, textToCompare);
            if (firstNameComparison) return firstNameComparison;
            let emailComparison = compareText(a.email, b.email, textToCompare);
            if (emailComparison) return emailComparison;
            let lastNameComparison = compareText(lastNameA, lastNameB, textToCompare);
            if (lastNameComparison) return lastNameComparison;
            return 0;
        });
        setFilteredSuggestions(sortedResults);
        if (document.getElementsByClassName("emails-list")[0]) document.getElementsByClassName("emails-list")[0].scrollTo({ top: 0, behavior: "smooth" });
    }, [props.suggestions, textEntered]);

    const handleKeyDown = (e: any) => {
        if ((e.keyCode === 13 || e.keyCode === 18) && e.shiftKey === false) {
            confirmEmail();
        } else if (e.keyCode === 8 && e.shiftKey === false) {
            if (!textEntered && props.onBackKeyPressed) props.onBackKeyPressed();
        }
    };

    const emailInputChanged = (event: any) => {
        setEmail(event.target.value);
        let allEmailsValid = true;
        textToEmails(event.target.value || "").forEach(email => (allEmailsValid = allEmailsValid && isEmail(email)));
        if (!allEmailsValid) setError(errorMessage);
        if (props.onTextEntered) props.onTextEntered(event.target.value);
        // @ts-ignore
        setTimeout(() => inputRef.current && inputRef.current.focus(), 1000);
    };

    const handlePaste = (e: any) => {
        let pastedText = e.clipboardData.getData("text/plain");
        let extractedEmails = textToEmails(pastedText);
        setTimeout(() => ((inputRef.current || { value: "" }).value = extractedEmails.join(", ")), 100);
        setTimeout(() => e.target.blur(), 200);
    };

    const setEmail = (text = "") => {
        setTextEntered(text);
        if (!isEmail(text)) {
            setError(errorMessage);
        } else {
            setError("");
        }
    };

    const textToEmails = (text: string = ""): string[] => {
        let emails = text.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g) || [];
        let uniqEmails: string[] = [];
        emails.forEach(item => {
            if (uniqEmails.indexOf(item) < 0) uniqEmails.push(item);
        });
        return uniqEmails;
    };

    const confirmEmail = () => {
        if (props.onBlur) props.onBlur();
        if (!error && props.onEmailsAdd) {
            if ((inputRef.current || { value: "" }).value.length) {
                let emails = textToEmails((inputRef.current || { value: "" }).value);
                emails = emails.filter((email: any) => !!email);
                props.onEmailsAdd(emails);
                setEmail("");
                (inputRef.current || { value: "" }).value = "";
                let allEmailsValid = emails.length > 0;
                emails.forEach(email => (allEmailsValid = allEmailsValid && isEmail(email)));
            }
        }
    };

    const hideSuggestionOnClickAway = () => {
        setSuggestionsOpen(false);
        confirmEmail();
    };

    const suggestionClicked = (suggestion: EmailSuggestion) => {
        setSuggestionsOpen(false);
        setEmail("");
        if (props.onEmailsAdd) props.onEmailsAdd([suggestion.email]);
        setTimeout(() => {
            if ((inputRef.current || { value: "" }).value.length) (inputRef.current || { value: "" }).value = "";
        }, 100);
        document.getElementById(props.id || "")?.focus();
    };

    return (
        <ClickAwayListener onClickAway={hideSuggestionOnClickAway}>
            <div className="position-relative">
                <div className="input-editable d-flex">
                    <ThemeProvider theme={theme}>
                        <Tooltip open={!!error && !!textEntered && suggestionsOpen} placement="top" title={error}>
                            <input
                                type="email"
                                ref={inputRef}
                                id={props.id}
                                autoFocus
                                autoComplete="off"
                                placeholder="Enter emails"
                                className={"w-100 show-focus show-border-bottom mt-0 " + (!!error && textEntered && suggestionsOpen ? " has-error " : "")}
                                style={{ marginTop: "5px" }}
                                onBlur={confirmEmail}
                                onKeyDown={handleKeyDown}
                                onFocus={() => setSuggestionsOpen(true)}
                                onChange={emailInputChanged}
                                onPaste={handlePaste}
                            />
                        </Tooltip>
                        <input type="email" name="email" style={{ display: "none" }}></input>
                        <Popper open={suggestionsOpen} anchorEl={inputRef.current}>
                            <Paper elevation={3}>
                                <List className="emails-list" sx={{ maxHeight: "200px", overflow: "auto" }}>
                                    {filteredSuggestions.map((suggestion: any, i: number) => (
                                        <ListItem key={i} disablePadding>
                                            <ListItemButton onClick={() => suggestionClicked(suggestion)}>
                                                <div className="menu-text">
                                                    <div>{suggestion.name || suggestion.email}</div>
                                                    <div className="font-light-color">{suggestion.email}</div>
                                                </div>
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </Popper>
                    </ThemeProvider>
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default AddEmailControl;
