import { FC, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogTitle, Divider, IconButton, LinearProgress } from "@mui/material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { isDesktop, isTablet } from "react-device-detect";
import { PostStatus } from "@emovid/payloads-library";
import { Close } from "@mui/icons-material";
import copy from "copy-to-clipboard";
import ToastService from "../../Common/ToastService";
import { Paths } from "../../Common/AppRoutes";
import { pushTagEvent, updatePostById } from "../../Common/Helper";
import BadgedTabs, { TabData } from "../../Common/BadgedTabs";
import { SHARE_OPTIONS } from "./ShareOptions";
import { SECURE_IMAGE_URL } from "../../Common/Constants";

export enum EmbedOptionType {
    HTML = 0,
    IMAGE = 1
}

interface EmbedOptionsDialogProps {
    show: boolean;
    shareLink: string;
    emovidId?: string;
    postId?: string;
    emailThumbnailUrl?: string;
    gifUrl?: string;
    updateStatusOnCopy?: boolean;
    onCancelClick: () => void;
    privateReplies: boolean;
    originalRecipientsCanView: boolean;
    signedInUsersCanView: boolean;
}

const EmbedOptionsDialog: FC<EmbedOptionsDialogProps> = ({
    emovidId,
    shareLink,
    postId,
    show,
    gifUrl,
    emailThumbnailUrl,
    onCancelClick,
    updateStatusOnCopy,
    privateReplies,
    originalRecipientsCanView,
    signedInUsersCanView
}: EmbedOptionsDialogProps) => {
    const [selectedEmbedOption, setSelectedEmbedOption] = useState<EmbedOptionType>(EmbedOptionType.HTML);
    const [copyInProgress, setCopyInProgress] = useState<boolean>(false);
    const [html, setHtml] = useState<string>("");

    const mailClientTabs: TabData[] = [
        {
            id: EmbedOptionType.HTML,
            title: "HTML",
            iconUrl: "/icons/embed-code.svg"
        },
        {
            id: EmbedOptionType.IMAGE,
            title: "Image",
            iconUrl: "/icons/image.svg"
        }
    ];

    useEffect(() => {
        if ((gifUrl || emailThumbnailUrl) && shareLink) setHtmlFromData();
    }, [gifUrl, emailThumbnailUrl, shareLink, originalRecipientsCanView, signedInUsersCanView]);

    const shareLinkCopyEvent = (asHtml: boolean, linkType: string) => {
        pushTagEvent("e_share_link_copied", {
            event_id: emovidId,
            post_id: postId,
            sec_private_replies: privateReplies ? "on" : "off",
            sec_only_email: originalRecipientsCanView ? "on" : "off",
            sec_only_signed_in: signedInUsersCanView ? "on" : "off",
            link_type: linkType,
            as_html: asHtml
        });
    };

    const copyHtmlClicked = async () => {
        setCopyInProgress(true);
        try {
            window.ClipboardItem
                ? await navigator.clipboard.write([new ClipboardItem({ "text/plain": new Blob([`${html}`], { type: "text/plain" }) })])
                : copy(html, { format: "text/plain" });
            if (postId && !!updateStatusOnCopy) await updatePostById(postId, { post_status: PostStatus.DRAFT_SHARABLE_LINK });
            ToastService.success(SHARE_OPTIONS[2].copy_message);
        } catch (error) {
            console.log("Error: ", error);
        }
        setCopyInProgress(false);
        try {
            shareLinkCopyEvent(false, SHARE_OPTIONS[2].id);
        } catch (error) {
            console.error("Failed to track share link copy event:", error);
        }
    };

    const copyLinkClicked = async () => {
        try {
            window.ClipboardItem
                ? await navigator.clipboard.write([new ClipboardItem({ "text/plain": new Blob([`${shareLink}`], { type: "text/plain" }) })])
                : copy(shareLink, { format: "text/plain" });
            if (postId && !!updateStatusOnCopy) await updatePostById(postId, { post_status: PostStatus.DRAFT_SHARABLE_LINK });
            ToastService.success("Link copied");
        } catch (error) {
            console.log("Error: ", error);
        }
        try {
            shareLinkCopyEvent(false, SHARE_OPTIONS[2].id);
        } catch (error) {
            console.error("Failed to track share link copy event:", error);
        }
    };

    const downloadImageClicked = async () => {
        try {
            setCopyInProgress(true);
            const originalImage = !(originalRecipientsCanView || signedInUsersCanView) ? gifUrl || emailThumbnailUrl || "" : "/images/secure_video_image_small.jpg";
            const image = await fetch(originalImage);
            const nameSplit = originalImage.split("/");
            const duplicateName = nameSplit.pop();
            const imageBlob = await image.blob();
            const imageURL = URL.createObjectURL(imageBlob);
            const link = document.createElement("a");
            link.href = imageURL;
            link.download = "" + duplicateName + "";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setCopyInProgress(false);
        } catch (error) {
            console.error("Error downloading image:", error);
            ToastService.alertableError("Failed to download image. Please try again.");
            setCopyInProgress(false);
        }
    };

    const setHtmlFromData = () => {
        let thumbnailUrl = SECURE_IMAGE_URL;
        if (!(originalRecipientsCanView || signedInUsersCanView)) {
            thumbnailUrl = gifUrl || emailThumbnailUrl || "";
        } else thumbnailUrl = SECURE_IMAGE_URL;
        setHtml(`<table border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout:fixed">
    <tbody>
        <tr style="width:100%">
            <td colspan="2" style="font-size:6px;line-height:10px;padding:0" valign="top" align="center">
                <a href="${shareLink}" target="_blank">
                    <img style="display:block;color:#000000;text-decoration:none;max-width:100%!important;object-fit:contain;height:auto!important;margin:auto;" width="540" alt="" src="${thumbnailUrl}"/>
                </a>
            </td>
        </tr>
    </tbody>
</table>`);
    };

    const tabChanged = (index: number) => {
        setSelectedEmbedOption(index);
    };

    return (
        <Dialog className="dialog-m-2" onClose={() => onCancelClick()} open={show} fullWidth maxWidth={false}>
            <div className="pt-3 ps-0 pe-0">
                <DialogTitle className="w-100 text-center p-0">Embed Options</DialogTitle>
                <Box position="absolute" top={5} right={5}>
                    <IconButton onClick={() => onCancelClick()}>
                        <Close />
                    </IconButton>
                </Box>
            </div>
            <div className="ms-4">
                <BadgedTabs tabs={mailClientTabs} selectedTabIndex={selectedEmbedOption} tabChanged={tabChanged}></BadgedTabs>
            </div>
            <Divider className="m-2" />
            <div className={"w-100 " + (copyInProgress ? "" : "opacity-0")}>
                <LinearProgress />
            </div>
            {selectedEmbedOption === EmbedOptionType.HTML && (
                <div className="d-flex w-100 p-3 pt-0 flex-wrap">
                    <div className={"pe-3 preview-section " + (isDesktop || isTablet ? "w-50" : "w-100")}>
                        <div className="w-100 text-center pb-2">Preview</div>
                        <div dangerouslySetInnerHTML={{ __html: html }}></div>
                    </div>
                    <div className={"html-section ps-3 pt-3 " + (isDesktop || isTablet ? "border-left w-50" : "w-100")}>
                        <code className="text-gray" style={{ maxHeight: "250px", overflow: "auto" }}>
                            {html}
                        </code>
                        <div className="d-flex justify-content-end">
                            <Button className="d-flex mt-3 text-white" onClick={copyHtmlClicked}>
                                <div className="me-2">
                                    <ContentCopyRoundedIcon />
                                </div>
                                <span>Copy HTML</span>
                            </Button>
                        </div>
                        <Divider className="mt-3 mb-2" />
                        <div className="text-gray text-center mt-3 text-small">
                            By embedding Emovid videos on your site, you are agreeing to <a href={Paths.terms}>Emovid Terms of Service</a>
                        </div>
                    </div>
                </div>
            )}
            {selectedEmbedOption === EmbedOptionType.IMAGE && (
                <div className="d-flex w-100 p-3 pt-0 flex-wrap">
                    <div className={"pe-3 preview-section " + (isDesktop || isTablet ? "w-50" : "w-100")}>
                        <div className="w-100 text-center pb-2">Preview</div>
                        <table border={0} cellPadding={0} cellSpacing="0" width="100%" style={{ tableLayout: "fixed" }}>
                            <tbody>
                                <tr style={{ width: "100%" }}>
                                    <td colSpan={2} style={{ fontSize: "6px", lineHeight: "10px", padding: 0 }} valign="top" align="center">
                                        <img
                                            style={{
                                                display: "block",
                                                color: "#000000",
                                                textDecoration: "none",
                                                maxWidth: "100% !important",
                                                objectFit: "contain",
                                                height: "auto !important",
                                                maxHeight: "350px"
                                            }}
                                            alt=""
                                            src={!(originalRecipientsCanView || signedInUsersCanView) ? gifUrl || emailThumbnailUrl : SECURE_IMAGE_URL}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={"html-section ps-3 pt-3 " + (isDesktop || isTablet ? "border-left w-50" : "w-100")}>
                        <div>
                            Embed an image in your campaign by downloading the thumbnail and inserting it into your template. You could optionally link it with the Emovid by
                            copying the link
                        </div>
                        <Divider className="mt-3 mb-2" />
                        <code className="text-gray" style={{ maxHeight: "250px", overflow: "auto" }}>
                            {shareLink}
                        </code>
                        <div className="d-flex justify-content-end flex-wrap">
                            <Button className="d-flex mt-3 text-white me-3" onClick={downloadImageClicked}>
                                <div className="me-2">
                                    <DownloadRoundedIcon />
                                </div>
                                <span>Download Thumbnail</span>
                            </Button>
                            <Button className="d-flex mt-3 text-white" onClick={copyLinkClicked}>
                                <div className="me-2">
                                    <ContentCopyRoundedIcon />
                                </div>
                                <span>Copy Link</span>
                            </Button>
                        </div>
                        <Divider className="mt-3 mb-2" />
                        <div className="text-gray text-center mt-3 text-small">
                            By embedding Emovid videos on your site, you are agreeing to <a href={Paths.terms}>Emovid Terms of Service</a>
                        </div>
                    </div>
                </div>
            )}
        </Dialog>
    );
};

export default EmbedOptionsDialog;
