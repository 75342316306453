import { FC } from "react";
import { Box, Dialog, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import ShareInfo from "./ShareInfo";
import SharePlugin from "./SharePlugin";

interface ShareEventDialogProps {
    show: boolean;
    title?: string;
    emovidId?: string;
    postId?: string;
    userId?: string;
    creatorName?: string;
    emailThumbnailUrl?: string;
    gifUrl?: string;
    duration?: number;
    privateReplies: boolean;
    originalRecipientsCanView: boolean;
    signedInUsersCanView: boolean;
    onCopy?: () => void;
    updateStatusOnCopy?: boolean;
    onCancelClick: () => void;
    aiSummary?: string;
    notes?: string;
}

const ShareEventDialog: FC<ShareEventDialogProps> = ({
    emovidId,
    postId,
    userId,
    show,
    title,
    privateReplies,
    originalRecipientsCanView,
    signedInUsersCanView,
    creatorName,
    gifUrl,
    emailThumbnailUrl,
    duration,
    onCancelClick,
    updateStatusOnCopy,
    onCopy,
    aiSummary,
    notes
}: ShareEventDialogProps) => {
    return (
        <Dialog className="dialog-m-2" onClose={() => onCancelClick()} open={show} fullWidth>
            <div className="created-emovids-video-dialog ps-0 pe-0">
                <DialogTitle className="w-100 text-center">
                    Copy Shareable Links <ShareInfo />
                </DialogTitle>
                <Box position="absolute" top={5} right={5}>
                    <IconButton onClick={() => onCancelClick()}>
                        <Close />
                    </IconButton>
                </Box>
                <SharePlugin
                    transcriptAvailable={true}
                    title={title}
                    emovidId={emovidId}
                    postId={postId}
                    userId={userId}
                    creatorName={creatorName}
                    originalRecipientsCanView={originalRecipientsCanView}
                    signedInUsersCanView={signedInUsersCanView}
                    privateReplies={privateReplies}
                    emailThumbnailUrl={emailThumbnailUrl}
                    gifUrl={gifUrl}
                    duration={duration}
                    updateStatusOnCopy={updateStatusOnCopy}
                    onCopy={onCopy}
                    aiSummary={aiSummary}
                    notes={notes}
                />
            </div>
        </Dialog>
    );
};

export default ShareEventDialog;
