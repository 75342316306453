import { FC, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { ClickAwayListener, Divider } from "@mui/material";
import { HtmlTooltip } from "../../Common/HtmlTooltip";
import { CopyOptionProps, SHARE_OPTIONS } from "../Share/ShareOptions";

interface ShareInfoProps {}

const ShareInfo: FC<ShareInfoProps> = (props: ShareInfoProps) => {
    const [show, setShow] = useState<boolean>(false);

    return (
        <ClickAwayListener onClickAway={() => setShow(false)}>
            <HtmlTooltip
                title={
                    <div className="p-3 position-relative">
                        <button className="btn btn-link p-0 close-button position-absolute text-dark" style={{ right: 0 }} onClick={() => setShow(false)}>
                            <CloseIcon />
                        </button>
                        {SHARE_OPTIONS[0].show_option && (
                            <>
                                <div className="w-100 d-flex justify-content-center">
                                    <img alt={SHARE_OPTIONS[0].title} src={SHARE_OPTIONS[0].icon} width={30} />
                                </div>
                                <div className="pt-2 pb-4">
                                    Pasting a link into the email will automatically include the video thumbnail, an AI summary, and a link to the AI transcript and notes.
                                </div>
                                <Divider className="mb-3" style={{ borderColor: "gray" }} />
                            </>
                        )}
                        {SHARE_OPTIONS[2].show_option && (
                            <>
                                <div className="w-100 d-flex justify-content-center">
                                    <img alt={SHARE_OPTIONS[2].title} src={SHARE_OPTIONS[2].icon} width={30} />
                                </div>
                                <div className="pt-2 pb-4">Pasting the embed HTML will include the video thumbnail.</div>
                                <Divider className="mb-3" style={{ borderColor: "gray" }} />
                            </>
                        )}
                        <div className="w-100 d-flex justify-content-around">
                            {SHARE_OPTIONS.map((option: CopyOptionProps, i: number) => (
                                <img key={i} className={i !== 0 && i !== 2 && option.show_option ? "" : "d-none"} alt={option.title} src={option.icon} width={30} />
                            ))}
                        </div>
                        <div className="pt-2">Pasting a link to other sites or apps will usually include the video thumbnail.</div>
                    </div>
                }
                disableTouchListener
                open={show}
                onOpen={() => setShow(true)}
                onClose={() => setShow(false)}
            >
                <div style={{ display: "inline", padding: "5px" }} onClick={() => setShow(true)}>
                    <HelpOutlineRoundedIcon fontSize="small" style={{ color: "white" }} />
                </div>
            </HtmlTooltip>
        </ClickAwayListener>
    );
};

export default ShareInfo;
